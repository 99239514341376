/* global angular */
(function() {
	'use strict';

	angular.module('app.main.controller', []).controller('mainCtrl', function($scope, $rootScope, $state) {
		console.info('%c             ／￣￣￣￣＼\n            ／   _ノ    ＼\n            |   （ ●）（●）\n            |    （__人__）\n            |         ⌒´ﾉ　　　자네, 봐선 안될것을 봐버렸구만?\n             |          }\n              ヽ        }\n               ヽ､., __ノ\n　　　_, ````"l||:::＼ｰ-..,ノ,､.ﾞ,i ､\n　　/;;;;;::::|＿:::;､>､_　l|||||ﾞ!:ﾞ､-､_\n　丿;;;;:::::／:::::::ㅁㅁ`` ﾞ||i l＼>:::`ｰ､\n. i;;;;;;;;;:＼::::::::::＼ .||||i|::::ヽ|::!\n/;;;;;;;;;::::::＼:::::::::ヽ|||||::::::i:::|\n', 'font-size:12px; color:#62d;');

		$rootScope.appTitle = 'Growth';
		
		$scope.getPagename = function(d1, d2) {
			switch (d1) {
				case 'landing':
					return 'page-main';
				case 'product':
					switch (d2) {
						case 'go':
							return 'page-pro-go';
						case 'server':
							return 'page-pro-server';
						case 'storage':
							return 'page-pro-storage';
						default:
							return 'page-pro-go';
					}
				case 'business':
					return 'page-business';
				case 'aboutus':
					return 'page-aboutus';
				case 'work':
					return 'page-work';
				default:
					return 'page-main';
			}
			
		}
		
		$rootScope.$watch('state.path', function(newval, oldval) {
			if (newval == oldval) return;
			if (newval === undefined) return;
			$scope.pagename = $scope.getPagename(newval[1], newval[2]);
		});
	});
})();