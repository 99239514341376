/* global angular */
(function() {
	'use strict';
	
	var main = angular.module('app.main', [
		'app.main.controller',
		'app.main.factory'
	]);
	
	main.config(function($stateProvider) {
		$stateProvider.state('app.landing', {
			url: 'landing',
			views: {
				'header': {
					templateUrl: '/app/body/header/header.html',
					controller: 'headerCtrl'
				},
				'body': {
					templateUrl: '/app/body/landing/landing.html',
					controller: 'landingCtrl'
				}
			}
		}).state('app.product', {
			url: 'product',
			abstract: true,
			views: {
				'header': {
					templateUrl: '/app/body/header/header.html',
					controller: 'headerCtrl'
				},
				'body': {
					templateUrl: '/app/body/product/product.html',
					controller: 'productCtrl'
				}
			}
		}).state('app.business', {
			url: 'business',
			views: {
				'header': {
					templateUrl: '/app/body/header/header.html',
					controller: 'headerCtrl'
				},
				'body': {
					templateUrl: '/app/body/business/business.html',
					controller: 'businessCtrl'
				}
			}
		}).state('app.work', {
			url: 'work',
			views: {
				'header': {
					templateUrl: '/app/body/header/header.html',
					controller: 'headerCtrl'
				},
				'body': {
					templateUrl: '/app/body/work/work.html',
					controller: 'workCtrl'
				}
			}
		}).state('app.aboutus', {
			url: 'aboutus',
			views: {
				'header': {
					templateUrl: '/app/body/header/header.html',
					controller: 'headerCtrl'
				},
				'body': {
					templateUrl: '/app/body/aboutus/aboutus.html',
					controller: 'aboutusCtrl'
				}
			}
		});
		
		
	});
	
})();