/* global angular */
(function() {
	'use strict';
	var app = angular.module('app', [
		'ngSanitize',
		'ngResource',
		'ui.router',
		'ui.bootstrap',
		'app.main',
		'app.header',
		'app.landing',
		'app.product',
		'app.work',
		'app.aboutus',
		'app.business'
	]);
	
	app.config(function ($stateProvider, $urlRouterProvider, $locationProvider) {
		$stateProvider.state('app', {
			url: '/',
			templateUrl: '/app/body/main.html',
			abstract: true,
			controller: function($scope, $state) {
				$state.go('app.landing');
				//console.log($state);
			}
		});
		$locationProvider.html5Mode(true);
		$urlRouterProvider.otherwise('landing');
	});
	
	app.run(function($rootScope, $state) {
		$rootScope.$on('$stateChangeSuccess', function() {
			$rootScope.state = {
				state: $state.current,
				name: $state.current.name.split('.')[1],
				path: $state.current.name.split('.')
			};
		});
	});
	
	app.constant('SETTINGS', {
		server: {
			url: '/',
			appName: 'Baas.Todo',
			appRev: 'v1/'
		}
	});
	
	app.directive('ngEnter', function () {
		return function (scope, element, attrs) {
			element.bind("keydown keypress", function (event) {
				if (event.which === 13) {
					scope.$apply(function () {
						scope.$eval(attrs.ngEnter);
					});
					event.preventDefault();
				}
			});
		};
	});
})();
