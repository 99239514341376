/* global angular */
(function() {
	'use strict';
	
	var product = angular.module('app.product', [
		'app.product.controller'
	]);
	
	product.config(function($stateProvider) {
		$stateProvider.state('app.product.go', {
			url: '/go',
			views: {
				'product-item': {
					templateUrl: '/app/body/product/go/go.html'
				}
			}
		});
	}).config(function($stateProvider) {
		$stateProvider.state('app.product.server', {
			url: '/server',
			views: {
				'product-item': {
					templateUrl: '/app/body/product/server/server.html'
				}
			}
		});
	}).config(function($stateProvider) {
		$stateProvider.state('app.product.storage', {
			url: '/storage',
			views: {
				'product-item': {
					templateUrl: '/app/body/product/storage/storage.html'
				}
			}
		});
	});
	
})();