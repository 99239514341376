/* global angular */
(function() {
	'use strict';
	
	angular.module('app.work.controller', []).controller('workCtrl', function($scope, $rootScope, $state, $http, $q) {
		//console.info('work.CONTROLLER LOADED');
		$scope.order = 'project';
		
		$scope.project = {
			calculatePosition: function() {
				/*$scope.project.model.projects.forEach(function() {
					
				});
				$scope.project.model.items.forEach(function() {
					
				});*/
			},
			get: function() {
				$http.get('/data/project.item.json').success(function(data) {
					$scope.project.model.items = data.projects;
				}).then(function() {
					$http.get('/data/project.projects.json').success(function(data) {
						$scope.project.model.projects = data.projects;
					});
				}).then(function() {
					$scope.project.calculatePosition();
				});
			},
			model: {}
		};
		
		function init() {
			$scope.project.get();
		}
		
		init();
	});
})();
