/* global angular */
(function() {
	'use strict';
	
	angular.module('app.main.factory', []).factory('mainFactory', function(SETTINGS, $resource) {
		var server = SETTINGS.server.url + SETTINGS.server.appName + SETTINGS.server.appRev;
		return {
			tree: $resource(server + 'api/url', {
			}, {
				get: {
					method: 'GET',
					isArray: false
				}
			})
		};
	});
})();
